import React from "react"
import Seo from "../../components/seo"
import Breadcrumbs from "../../components/Breadcrumbs"

const DeliveryInformationPage = () => {
  return (
    <>
      <Seo title="Lieferinformationen" />
      <Breadcrumbs title={"Lieferinformationen"} />

      <div className="container py-5"></div>
    </>
  )
}

export default DeliveryInformationPage
